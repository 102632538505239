import React from "react"
import { DefaultLayout } from "layouts"
import { CaseStudies } from "containers"
import { Lists } from "components"

import bgHeader from "../../images/bg-header.png"

import caseStudyThumbnail01 from "../../images/case-study-thumbnail-01.png"
import caseStudyThumbnail02 from "../../images/case-study-thumbnail-02.png"
import caseStudyThumbnail03 from "../../images/case-study-thumbnail-03.png"
import caseStudyThumbnail04 from "../../images/case-study-thumbnail-04.png"
import caseStudyThumbnail05 from "../../images/case-study-thumbnail-05.png"
import caseStudyThumbnail06 from "../../images/case-study-thumbnail-06.png"
import caseStudyThumbnail07 from "../../images/case-study-thumbnail-07.png"
import caseStudyThumbnail08 from "../../images/case-study-thumbnail-08.png"
import caseStudyThumbnail09 from "../../images/case-study-thumbnail-09.png"

const caseStudiesListProps: ICaseStudiesList = {
  headline: "Latest case studies",
  description:
    "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita ",
  caseStudies: [
    {
      title: "Ice & Stripes Another cold story",
      thumbnail: caseStudyThumbnail01,
      category: "Paper Name One",
      description: "",
      client: "",
      date: "",
      gallery: [],
      slug: "/case-studies/interior",
    },
    {
      title: "The Real open boxes packaging",
      thumbnail: caseStudyThumbnail02,
      category: "Paper Name One",
      description: "",
      client: "",
      date: "",
      gallery: [],
      slug: "/case-studies/interior",
    },
    {
      title: "Pink Soft Crystal Perls No-Impact",
      thumbnail: caseStudyThumbnail03,
      category: "Paper Name One",
      description: "",
      client: "",
      date: "",
      gallery: [],
      slug: "/case-studies/interior",
    },
    {
      title: "Dark Coffee Beans, but with color",
      thumbnail: caseStudyThumbnail04,
      category: "Paper Name One",
      description: "",
      client: "",
      date: "",
      gallery: [],
      slug: "/case-studies/interior",
    },
    {
      title: "New Zealand Real Coffee Packaging",
      thumbnail: caseStudyThumbnail05,
      category: "Paper Name One",
      description: "",
      client: "",
      date: "",
      gallery: [],
      slug: "/case-studies/interior",
    },
    {
      title: "Thermal Paper for fresh meat",
      thumbnail: caseStudyThumbnail06,
      category: "Paper Name One",
      description: "",
      client: "",
      date: "",
      gallery: [],
      slug: "/case-studies/interior",
    },
    {
      title: "Metallic Paper for Futuristic Ideas",
      thumbnail: caseStudyThumbnail07,
      category: "Paper Name One",
      description: "",
      client: "",
      date: "",
      gallery: [],
      slug: "/case-studies/interior",
    },
    {
      title: "Reusable Amazon Box for Eco Saving",
      thumbnail: caseStudyThumbnail08,
      category: "Paper Name One",
      description: "",
      client: "",
      date: "",
      gallery: [],
      slug: "/case-studies/interior",
    },
    {
      title: "The Coolest Box Beer ever made",
      thumbnail: caseStudyThumbnail09,
      category: "Paper Name One",
      description: "",
      client: "",
      date: "",
      gallery: [],
      slug: "/case-studies/interior",
    },
    {
      title: "The Real open boxes packaging",
      thumbnail: caseStudyThumbnail02,
      category: "Paper Name One",
      description: "",
      client: "",
      date: "",
      gallery: [],
      slug: "/case-studies/interior",
    },
    {
      title: "Pink Soft Crystal Perls No-Impact",
      thumbnail: caseStudyThumbnail03,
      category: "Paper Name One",
      description: "",
      client: "",
      date: "",
      gallery: [],
      slug: "/case-studies/interior",
    },
    {
      title: "Dark Coffee Beans, but with color",
      thumbnail: caseStudyThumbnail04,
      category: "Paper Name One",
      description: "",
      client: "",
      date: "",
      gallery: [],
      slug: "/case-studies/interior",
    },
    {
      title: "New Zealand Real Coffee Packaging",
      thumbnail: caseStudyThumbnail05,
      category: "Paper Name One",
      description: "",
      client: "",
      date: "",
      gallery: [],
      slug: "/case-studies/interior",
    },
    {
      title: "Thermal Paper for fresh meat",
      thumbnail: caseStudyThumbnail06,
      category: "Paper Name One",
      description: "",
      client: "",
      date: "",
      gallery: [],
      slug: "/case-studies/interior",
    },
    {
      title: "Metallic Paper for Futuristic Ideas",
      thumbnail: caseStudyThumbnail07,
      category: "Paper Name One",
      description: "",
      client: "",
      date: "",
      gallery: [],
      slug: "/case-studies/interior",
    },
    {
      title: "Reusable Amazon Box for Eco Saving",
      thumbnail: caseStudyThumbnail08,
      category: "Paper Name One",
      description: "",
      client: "",
      date: "",
      gallery: [],
      slug: "/case-studies/interior",
    },
  ],
}

const boxWrapperProps = {
  heroProps: {
    background: bgHeader,
    headline: "B&F Case Studies",
  },
  boxContent: <Lists.CaseStudies {...caseStudiesListProps} />,
  breadcrumbProps: {
    paths: [
      {
        path: "/",
        label: "Home",
      },
      {
        path: "/case-studies",
        label: "Case Stuides",
      },
    ],
  },
}

const caseStudiesProps: ICaseStudiesContainerProps = {
  boxWrapperProps,
}

const Index = (props: any) => {
  return (
    <DefaultLayout {...props}>
      <CaseStudies {...caseStudiesProps} />
    </DefaultLayout>
  )
}

export default Index
